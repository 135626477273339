var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"content-inputs"},[_c('vs-select',{ref:"v_select",staticClass:"select-hackmetrix-component",class:{
      'select-hackmetrix-component-outline': _vm.outline,
      'select-hackmetrix-component-outline-disabled': _vm.outline && _vm.disabled,
      'select-hackmetrix-component-disabled': _vm.disabled,
      'select-hackmetrix-component-success': _vm.valid,
      'select-hackmetrix-component-warning': _vm.error,
      'hide-select': !_vm.label,
    },attrs:{"label":_vm.label,"border":_vm.outline,"loading":_vm.loading,"placeholder":_vm.placehoder,"icon-after":_vm.iconAfter,"multiple":_vm.multiple,"collapse-chips":_vm.multiple,"disabled":_vm.disabled,"id":_vm.id},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.items),function(tr,i){return _c('vs-option',{key:i,attrs:{"label":tr.last_name ? tr.name + ' ' + tr.last_name : tr.name,"disabled":tr.disabled,"value":tr.value || tr.id}},[_vm._v(" "+_vm._s(tr.name)+" "),(tr.last_name)?_c('span',{staticClass:"spacing-left"},[_vm._v(_vm._s(tr.last_name))]):_vm._e()])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }