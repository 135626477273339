
import { Component, Vue, Prop, Watch, } from 'vue-property-decorator'
import { SelectInterface, } from '@/utils/Select'

@Component
export default class SelectComponent extends Vue {
  @Prop({ required: false, default: false, }) readonly label!: string
  @Prop({ required: false, default: 'vs-select', }) readonly id!: string
  @Prop({ required: false, default: '', }) readonly placehoder!: string
  @Prop({ required: false, default: false, }) readonly icon!: string
  @Prop({ required: false, default: false, }) readonly selected!: string
  @Prop({ required: false, default: false, }) readonly outline!: boolean
  @Prop({ required: false, default: false, }) readonly multiple!: boolean
  @Prop({ required: false, default: false, }) readonly loading!: boolean
  @Prop({ required: false, default: false, }) readonly iconAfter!: boolean
  @Prop({ required: false, default: false, }) readonly disabled!: boolean
  @Prop({ required: false, }) readonly valid!: boolean
  @Prop({ required: false, }) readonly error!: boolean
  @Prop({ required: false, }) readonly items!: SelectInterface
  private value = ''

  mounted () {
    if (this.selected) {
      this.value = this.selected
    }
  }

  @Watch('value')
  valueChage (): void {
    this.$emit('value', this.value)
  }

  private beforeDestroy () {
    const elements = document.querySelectorAll('.vs-select__options')
    if (elements) {
      elements.forEach((el) => el.remove())
    }
  }
}
