
import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { ContactsInterface } from '@/store/types/Business/Contacts'
import { CrudBusiness } from '@/store/types/Business/CrudBusiness'
import * as validations from '@/utils/Validations'
import {
  Normative,
  Documentation,
  Architecture,
  SelectInterface,
  Roles,
  Channels,
} from '@/utils/Select'

import InputHackmetrix from '@/components/InputHackmetrix/Index.vue'
import SelectHackmetrix from '@/components/SelectHackmetrix/Index.vue'
import ChipHackmetrix from '@/components/Chip/Index.vue'
import { FileUploadInterface } from '@/store/modules/dataroom/dataroomModule'
import { DataroomTemplate } from '@/store/types/Dataroom/DataroomTemplate'
import OverlayBackground from '@/components/OverlayBackground/Index.vue'

@Component({
  components: {
    InputHackmetrix,
    SelectHackmetrix,
    ChipHackmetrix,
    OverlayBackground,
  },
  computed: {
    ...mapGetters('PolimorficModule', ['getFramework']),
  },
})
export default class CreateBusiness extends Vue {
  // Vars for Create
  private name = ''
  private description = ''
  private documentation_level = ''
  private architecture: string[] = []
  private architecture_description: string | undefined = ''
  private framework_selected: number[] = []
  private framework_ids: number[] = []
  private saq_id: number[] = []
  private contacts: ContactsInterface[] = []
  private normative: SelectInterface[] = Normative
  private documentation: SelectInterface[] = Documentation
  private arcuitecture_options: SelectInterface[] = Architecture
  private channel_options: SelectInterface[] = Channels
  private channel_selected: string[] = []
  private roles: SelectInterface[] = Roles
  private contactsToForm: ContactsInterface[] = []
  private validator = validations
  private messageInfo = 'Campo requerido'
  private businessAvailable = true
  private fileToUpload: FileUploadInterface[] = []
  private datarooms: string[] = []
  private dataroomsName: string[] = []
  private files: DataroomTemplate[] = []
  private loaderUpload = false
  private uploaded = false
  private minutaName = ''
  private Frameworks = this.$store.state.PolimorficModule.PolimorficState
  private frameworks = []
  private saq = false
  private indexFramework = this.$store.state.PolimorficModule.PolimorficState.frameworks.data
  private itemSaq = this.indexFramework[0].saqs
  private saq_selected: number[] = []
  private newDomain = ''
  public companyDomains: string[] = []
  private wrongUrlMessage = 'Formato de dominio incorrecto'
  private isWrongUrl = false
  private loading = false
  private companyHubspot_id: number = 0
  public isDisable: boolean = false
  public hubspot_id_available: boolean = true
  public hubspot_id_is_number: boolean = true

  mounted() {
    this.resetVars()
    this.getFrameworks()
  }

  private resizeBox(v: any) {
    const a = document.getElementById(v)
    if (a) {
      a.style.height = 'auto'
      a.style.height = a.scrollHeight + 'px'
    }
  }

  private reUpload() {
    this.minutaName = ''
    this.dataroomsName = []
    this.datarooms = []
    this.fileToUpload = []
  }

  private previewFiles() {
    this.readbase64()
  }

  private readbase64() {
    const refFiles: any = this.$refs.filesBusiness
    const reader = new FileReader()
    reader.readAsDataURL(refFiles.files[0])
    reader.onload = () => {
      const fileAux: string = refFiles.files[0].name.toLowerCase()
      if (fileAux.split('.').pop() === 'pdf') {
        this.minutaName = fileAux
        this.dataroomsName.push(fileAux)
        const readerResult: string | undefined = reader?.result?.toString()
        if (readerResult) {
          this.datarooms.push(readerResult)
        }
        this.uploadFile()
      } else {
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: 'Error, Tipo de archivo no permitido',
          color_notification: 'danger',
        })
        return
      }
    }
  }

  private uploadFile() {
    this.loaderUpload = true
    this.files.push({ name: this.dataroomsName[0] })
    this.fileToUpload.push({
      filename: this.dataroomsName[0],
      data: this.datarooms[0],
    })
  }

  private getFrameworks() {
    this.loading = true
    this.$store
      .dispatch('PolimorficModule/getFrameworks')
      .then(() => {
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  }

  private selectedFramework(element: any) {
    this.framework_selected = element
    this.saq = element.includes(3)
  }

  private selectedSaq(element: any) {
    this.saq_selected = element
  }

  private selectedChannel(element: any) {
    this.channel_selected = element
  }

  private selectedArchitecture(element: any) {
    this.architecture = element
  }

  private watchValue(value: string) {
    this.loading = true
    this.$store.dispatch('BusinessModule/ExistBusiness', value).then((response) => {
      this.businessAvailable = response.data.available
      this.loading = false
    })
  }

  private watchHubspotId(hubspot_id: number) {
    this.loading = true
    const parsedHubspotId = Number(hubspot_id)
    if (isNaN(parsedHubspotId)) {
      this.hubspot_id_is_number = false
      this.loading = false;
    } else if (parsedHubspotId != 0) {
      this.$store.dispatch('BusinessModule/ExistHubspotId', hubspot_id).then((response) => {
        this.hubspot_id_available = response.data.available
        this.hubspot_id_is_number = true
      this.loading = false
    })
    }
    this.hubspot_id_is_number = false
    this.loading = false
  }

  private getName(value: string) {
    this.name = value
  }
  private getHubspotId(value: number) {
    this.companyHubspot_id = value
  }
  private getdescription(value: string) {
    this.description = value
  }
  private getDomain(value: string) {
    this.newDomain = value.trim()
  }
  private addDomain() {
    const fqdnPatter = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
    if (!fqdnPatter.test(this.newDomain.trim())) {
      this.isWrongUrl = true
    } else {
      this.isWrongUrl = false
      this.companyDomains.push(this.newDomain.trim())
    }
    this.newDomain = ''
  }

  private getdescriptionArchitecture(value: string) {
    this.architecture_description = value
  }

  private getReactive(element: any, key: any, id: number) {
    const pos = this.contacts
      .map(function (e) {
        return e.id
      })
      .indexOf(id)
    const varaux: any = this.contacts[pos]
    varaux[key] = element
    this.cleanDisabled()
    // this.cleanOptions()
  }

  private clearContact(id: number) {
    const index = this.contacts
      .map((x) => {
        return x.id
      })
      .indexOf(id)

    this.contacts.splice(index, 1)
    this.cleanDisabled()
  }

  private cleanOptions() {
    this.contacts.forEach((element) => {
      this.roles.forEach((data) => {
        if (element.roles?.indexOf(data.value) != -1 && data.value !== 'main') {
          data.disabled = true
        }
      })
    })
  }

  private cleanDisabled() {
    this.roles.forEach((data) => {
      if (data.value !== 'main') {
        data.disabled = false
      }
    })
  }

  private createBusiness() {
    this.isDisable = true
    // Validations before Insert
    this.contactsToForm = []

    if (!this.businessAvailable) {
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Error, Nombre de empresa no disponible',
        color_notification: 'danger',
      })
      this.isDisable = false
      return
    }

    if (
      this.name === '' ||
      this.description === '' ||
      this.framework_selected.length === 0 ||
      this.architecture.length === 0 ||
      this.contacts[0].name === '' ||
      this.contacts[0].last_name === '' ||
      this.contacts[0].email === '' ||
      this.architecture_description === '' ||
      this.channel_selected.length === 0 ||
      this.companyHubspot_id === 0
    ) {
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Error, Por Favor llenar los campos requeridos',
        color_notification: 'danger',
      })
      this.isDisable = false
      return
    }

    let mail_error = false
    let contact_error = false

    this.contacts.forEach((element) => {
      if (
        element.name !== '' &&
        element.last_name !== '' &&
        element.email !== '' &&
        element.roles?.length != 0
      ) {
        if (!validations.regexEmail.test(element.email)) {
          mail_error = true
          this.$store.dispatch('NotificactionsModule/OpenNotification', {
            message: 'Error, Existe un email inválido',
            color_notification: 'danger',
          })
          this.isDisable = false
          return
        }

        const contactData: ContactsInterface = {
          name: element.name,
          last_name: element.last_name,
          email: element.email,
          phone: element.phone,
          roles: element.roles,
        }

        this.contactsToForm.push(contactData)
      } else {
        contact_error = true
      }
    })

    if (mail_error) {
      return
    }

    if (contact_error) {
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Error, Debe completar correctamente los datos de los responsables',
        color_notification: 'danger',
      })
      this.isDisable = false
      return
    }

    const businessData: CrudBusiness = {
      name: this.name,
      description: this.description,
      documentation_level: this.documentation_level,
      architectures: this.architecture,
      architecture_description: this.architecture_description,
      framework_ids: this.framework_selected,
      saq_id: this.saq_selected,
      contacts_attributes: this.contactsToForm,
      channels: this.channel_selected,
      kickoff_data: this.fileToUpload,
      domains: this.companyDomains,
      hubspot_id: this.companyHubspot_id,
    }

    this.$store.dispatch('NotificactionsModule/OpenNotification', {
      message: 'Éxito, Creando empresa',
    })
    this.$store.dispatch('BusinessModule/createBusiness', businessData).then(() => {
      this.$mixpanel.track('Empresa Agregada', {
        Name: this.name,
        Arquitectura: this.architecture,
        Normativas: businessData.framework_ids,
        saqs: this.saq_selected,
        Canales: businessData.channels,
      })
      this.resetVars()
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Éxito, Empresa creada',
      })
      this.$router.go(-1)
      this.isDisable = false
    })
  }

  private resetVars() {
    this.name = ''
    this.description = ''
    this.architecture_description = ''
    this.contacts = []
    this.contactsToForm = []
    this.contacts.push({
      id: 1,
      name: '',
      last_name: '',
      email: '',
      phone: '',
      roles: ['main'],
      disabled: true,
      title: 'Responsable Principal',
    })
    this.dataroomsName = []
    this.datarooms = []
    this.fileToUpload = []
    this.companyDomains = []
  }

  private addContact() {
    const contactData: ContactsInterface = {
      id: Math.floor(Math.random() * 10000),
      name: '',
      last_name: '',
      email: '',
      phone: '',
      roles: [],
      title: 'Responsable',
    }

    this.contacts.push(contactData)
    this.cleanDisabled()
    // this.cleanOptions()
  }

  get infoMessageInput() {
    if (!this.businessAvailable) {
      return 'Nombre de empresa no disponible'
    } else {
      return 'Campo Requerido'
    }
  }
  get infoMessageHubspotInput() {
    if (!this.hubspot_id_available) {
      return 'Hubspot ID no disponible'
    } else {
      return 'Campo Requerido'
    }
  }
}
