export interface SelectInterface {
  name: string
  value: string
  disabled?: boolean
  transition?: { implementer: string[]; client: string[] }
}

export const Normative: SelectInterface[] = [
  {
    name: 'ISO 27002',
    value: '1',
    disabled: false,
  },
  {
    name: 'PCI DSS',
    value: '2',
    disabled: true,
  },
  {
    name: 'HIPPA',
    value: '3',
    disabled: true,
  },
  {
    name: 'GDPR',
    value: '4',
    disabled: true,
  },
  {
    name: 'NIST',
    value: '5',
    disabled: true,
  },
]

export const TypeSaq: SelectInterface[] = [
  {
    value: '1',
    name: 'A',
  },
  {
    value: '2',
    name: 'A-EP',
  },
  {
    value: '3',
    name: 'B',
  },
  {
    value: '4',
    name: 'B-IP',
  },
  {
    value: '5',
    name: 'C',
  },
  {
    value: '6',
    name: 'C-VT',
  },
  {
    value: '7',
    name: 'D',
  },
  {
    value: '8',
    name: 'D-SP',
  },
  {
    value: '9',
    name: 'P2PE-HW',
  },
]

export const StatusAct: SelectInterface[] = [
  {
    name: 'Pendiente',
    value: 'pending',
    disabled: false,
    transition: {
      implementer: [
        'pending',
        'progress',
        'hackmetrix_review',
        'company_review',
        'hackmetrix_approved',
        'done',
      ],
      client: [
        'pending',
        'progress',
        'hackmetrix_review',
        'company_review',
        'hackmetrix_approved',
        'done',
      ],
    },
  },
  {
    name: 'En curso',
    value: 'progress',
    disabled: false,
    transition: {
      implementer: [
        'pending',
        'progress',
        'hackmetrix_review',
        'company_review',
        'hackmetrix_approved',
        'done',
      ],
      client: [
        'pending',
        'progress',
        'hackmetrix_review',
        'company_review',
        'hackmetrix_approved',
        'done',
      ],
    },
  },
  {
    name: 'En revisión Hackmetrix',
    value: 'hackmetrix_review',
    disabled: false,
    transition: {
      implementer: [
        'pending',
        'progress',
        'hackmetrix_review',
        'company_review',
        'hackmetrix_approved',
        'done',
      ],
      client: [
        'pending',
        'progress',
        'hackmetrix_review',
        'company_review',
        'hackmetrix_approved',
        'done',
      ],
    },
  },
  {
    name: 'En revisión Cliente',
    value: 'company_review',
    disabled: false,
    transition: {
      implementer: [
        'pending',
        'progress',
        'hackmetrix_review',
        'company_review',
        'hackmetrix_approved',
        'done',
      ],
      client: [
        'pending',
        'progress',
        'hackmetrix_review',
        'company_review',
        'hackmetrix_approved',
        'done',
      ],
    },
  },
  {
    name: 'Por aprobar cliente',
    value: 'hackmetrix_approved',
    disabled: false,
    transition: {
      implementer: [
        'pending',
        'progress',
        'hackmetrix_review',
        'company_review',
        'hackmetrix_approved',
        'done',
      ],
      client: [
        'pending',
        'progress',
        'hackmetrix_review',
        'company_review',
        'hackmetrix_approved',
        'done',
      ],
    },
  },
  {
    name: 'Terminado',
    value: 'done',
    disabled: true,
    transition: {
      implementer: [
        'pending',
        'progress',
        'hackmetrix_review',
        'company_review',
        'hackmetrix_approved',
        'done',
      ],
      client: [
        'pending',
        'progress',
        'hackmetrix_review',
        'company_review',
        'hackmetrix_approved',
        'done',
      ],
    },
  },
]

export const Documentation: SelectInterface[] = [
  {
    name: 'Alto',
    value: 'high',
    disabled: false,
  },
  {
    name: 'Medio',
    value: 'medium',
    disabled: false,
  },
  {
    name: 'Bajo',
    value: 'low',
    disabled: false,
  },
  {
    name: 'No existe',
    value: 'neither',
    disabled: false,
  },
]

export const Role: SelectInterface[] = [
  {
    name: 'Sin invitación',
    value: 'unbidden',
    disabled: false,
  },
  {
    name: 'Activo',
    value: 'active',
    disabled: false,
  },
  {
    name: 'Invitación pendiente',
    value: 'pending',
    disabled: false,
  },
  {
    name: 'Desactivada',
    value: 'deactivated',
    disabled: false,
  },
]

export const Architecture: SelectInterface[] = [
  {
    name: 'AWS',
    value: 'aws',
    disabled: false,
  },
  {
    name: 'AZURE',
    value: 'azure',
    disabled: false,
  },
  {
    name: 'GCLOUD',
    value: 'gcloud',
    disabled: false,
  },
  {
    name: 'DIGITAL OCEAN',
    value: 'digital_ocean',
    disabled: false,
  },
  {
    name: 'Otro',
    value: 'other',
    disabled: false,
  },
]

export const Roles: SelectInterface[] = [
  {
    name: 'Responsable Principal',
    value: 'main',
    disabled: true,
  },
  // {
  //   name: 'Administrador',
  //   value: 'admin',
  //   disabled: false,
  // },
  // Legacy code: Se hizo un mock del rol de Administrador manteniendo un valor legacy
  // esto es para la transicion entre el uso de contactos a usuarios en el modulo
  {
    name: 'Administrador',
    value: 'technology_and_development',
    disabled: false,
  },
  // {
  //   name: 'Seguridad Informática',
  //   value: 'informatic_security',
  //   disabled: false,
  // },
  // {
  //   name: 'Seguridad Física',
  //   value: 'physical_security',
  //   disabled: false,
  // },
  // {
  //   name: 'Recursos Humanos',
  //   value: 'human_resources',
  //   disabled: false,
  // },
  // {
  //   name: 'Contractual',
  //   value: 'contractual',
  //   disabled: false,
  // },
]

export const Channels: SelectInterface[] = [
  {
    name: 'Slack',
    value: 'slack',
    disabled: false,
  },
  {
    name: 'Correo Electrónico',
    value: 'email',
    disabled: false,
  },
  {
    name: 'Teams',
    value: 'teams',
    disabled: false,
  },
  {
    name: 'Hangout',
    value: 'hangout',
    disabled: false,
  },
  {
    name: 'Whatsapp',
    value: 'whatsapp',
    disabled: false,
  },
  {
    name: 'Otro',
    value: 'other',
    disabled: false,
  },
]

export const Frecuency: SelectInterface[] = [
  {
    name: 'Revisión cada 3 meses',
    value: 'three_months',
  },
  {
    name: 'Revisión cada 6 meses',
    value: 'six_months',
  },
  {
    name: 'Revisión cada 1 año',
    value: 'one_year',
  },
]
